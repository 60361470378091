<template>
    <f7-page name="settings">
        <f7-navbar title="Kategorien" back-link></f7-navbar>
        <f7-list>
            <f7-list-item 
                title="Art Card" 
                :link="`/categoryDetail/Art Card/`">
            </f7-list-item>
            <f7-list-item 
                title="3D Pop-up-Card" 
                :link="`/categoryDetail/Pop-up/`">
            </f7-list-item>
            <f7-list-item
                title="Paper Quilling-Card"
                :link="`/categoryDetail/Quilling/`"
            ></f7-list-item>
            <f7-list-item
                title="Jaab- und fromJude Karte"
                :link="`/categoryDetail/Jaab- und fromJude/`"
            ></f7-list-item>
            <f7-list-item
                title="Growing Paper-Card"
                :link="`/categoryDetail/Growing/`"
            ></f7-list-item>
            <f7-list-item
                title="Scherenschnitt-Karte"
                :link="`/categoryDetail/Scherenschnitt/`"
            ></f7-list-item>
            <f7-list-item 
                title="Tea Bag-Card" 
                :link="`/categoryDetail/Tea Bag/`">
            </f7-list-item>
            <f7-list-item
                title="Iris Folding Card"
                :link="`/categoryDetail/Iris Folding/`"
            ></f7-list-item>
            <f7-list-item
                title="Herbarium- und Flora-Karte"
                :link="`/categoryDetail/Herbarium- und Flora/`"
            ></f7-list-item>
            <f7-list-item
                title="Tunnel-Karte"
                :link="`/categoryDetail/Tunnel/`"
            ></f7-list-item>
            <f7-list-item title="5er-Karte" :link="`/categoryDetail/5er/`"></f7-list-item>
            <f7-list-item title="Puzzle-Karte" :link="`/categoryDetail/Puzzle/`"></f7-list-item>
            <f7-list-item title="Geschenkbox" :link="`/categoryDetail/Geschenkbox/`"></f7-list-item>
            <f7-list-item title="Zubehör" :link="`/categoryDetail/Zubehör/`"></f7-list-item>
        </f7-list>
    </f7-page>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: [],

    methods: {},

    mounted() {},
};
</script>

<style scoped>
.list {
    margin-top: 0px;
}
</style>
